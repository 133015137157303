<template>
  <transition
    enter-active-class="transform transition ease-out duration-300"
    enter-class="translate-y-2 opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    leave-active-class="transform transition ease-in duration-200"
    leave-class="translate-y-0 opacity-100"
    leave-to-class="translate-y-2 opacity-0"
  >
    <div
      v-show="show"
      class="fixed bottom-0 right-0 z-50 mb-4 mr-4 max-w-sm w-full bg-red-50 rounded-lg pointer-events-auto shadow-[0_4px_12px_rgba(220,38,38,0.15)]"
    >
      <div class="p-3">
        <div class="flex items-start space-x-3">
          <!-- Warning Icon -->
          <div class="flex-shrink-0 pt-0.5">
            <svg
              class="h-5 w-5 text-red-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                clip-rule="evenodd"
              />
            </svg>
          </div>

          <!-- Content -->
          <div class="flex-1 pt-0.5">
            <div class="flex justify-between">
              <p class="text-sm font-medium text-red-800">
                {{ title }}
              </p>
              <span class="ml-2 text-sm font-bold text-red-600"
                >{{ countdown }}s</span
              >
            </div>
            <p class="mt-1 text-sm text-red-700">
              {{ message }}
            </p>

            <!-- Buttons -->
            <div class="mt-3 flex justify-end space-x-3">
              <button
                @click.stop="$emit('stay-active')"
                class="px-3 py-1.5 bg-red-600 text-white text-sm font-medium rounded hover:bg-red-700 transition-colors duration-150 shadow-sm"
              >
                Stay Active
              </button>
              <button
                @click.stop="$emit('logout')"
                class="px-3 py-1.5 bg-white text-red-700 text-sm font-medium rounded hover:bg-red-50 transition-colors duration-150 border border-red-200"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Toast',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    countdown: {
      type: Number,
      required: true
    }
  }
};
</script>

<style scoped>
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
</style>
