<template>
  <div id="app" class="flex flex-col sm:flex-row h-full items-stretch">
    <navigation v-if="(isLoggedIn && !isPacker) || (isAdmin && isLoggedIn)" />
    <router-view
      :class="
        isSingleScroll
          ? 'flex-grow relative overflow-y-auto pb-16'
          : 'flex-grow relative'
      "
      id="content-wrapper"
    />
    <qz-init v-if="hasPrintKeys && isPackingMachine" />
    <inactivity-tracker
      :is-logged-in="isLoggedIn"
      :is-packing-machine="isPackingMachine"
    />
  </div>
</template>
<script>
import { isBoolean } from 'lodash/fp';
import { mapActions, mapGetters } from 'vuex';
import { isSameDay } from 'date-fns';
import http from '@/http';
import QzInit from '@/components/common/printer/QzInit.vue';
import utils from '@/utils';
import Navigation from '@/components/Nav';
import InactivityTracker from '@/components/common/InactivityTracker.vue';

export default {
  metaInfo: {
    title: 'CCC Admin',
    titleTemplate: '%s - CCC Admin'
  },
  components: {
    Navigation,
    QzInit,
    InactivityTracker
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isAdmin: 'isAdmin',
      isPacker: 'isPacker',
      printKeys: 'getPrintKeys',
      isPackingMachine: 'isPackingMachine',
      isTestingMachine: 'isTestingMachine',
      analyticsHistory: 'analyticsHistoryGetter'
    }),
    hasPrintKeys() {
      return this.printKeys.cert && this.printKeys.private;
    },
    isSingleScroll() {
      return this.$route.name !== 'packingProfile';
    }
  },
  methods: {
    ...mapActions({
      setProgress: 'setPackingProgress'
    }),
    isToday(val) {
      const check = new Date(val);
      const today = new Date();
      return isSameDay(check, today);
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$store.dispatch('getLoggedInUser').then(response => {
        const user = response;
        const hash = btoa(user.email);

        this.$mixpanel.identify(hash);
        this.$mixpanel.people.set({ $name: user.name, $email: user.email });
      });
      this.$store.dispatch('getProducts');
      const shippingLastChecked = utils.getStorage('shippingOptions', 'local');

      if (!this.isToday(shippingLastChecked?.lastChecked)) {
        this.$store.dispatch('getShippingOptions');
        this.$store.dispatch('getShippingOptions', { type: 'subscription' });
        this.$store.dispatch('getShippingOptions', { type: 'taster' });
      } else {
        this.$store.commit('set_all_shipping', shippingLastChecked);
      }
      this.$store.dispatch('getPortalUsers');
      if (isBoolean(utils.getStorage('isPackingMachine', 'local'))) {
        this.$store.dispatch('getKey', 'cert');
        this.$store.dispatch('getKey', 'private');
      } else {
        this.$store.commit('set_is_packing_machine', true);
        utils.setStorage('isPackingMachine', true, 'local');
      }
      if (utils.getStorage('shippingOptionSettings', 'local')) {
        this.$store.commit(
          'set_shipping_options',
          utils.getStorage('shippingOptionSettings', 'local')
        );
      }
      if (this.analyticsHistory) {
        this.$store.dispatch('checkHistory', this.analyticsHistory).then(() => {
          this.$store.dispatch('getHistoricalAnalytics');
        });
      } else {
        this.$store.dispatch('getHistoricalAnalytics');
      }

      this.$store.dispatch('getAnalyticsHelpers');
    }

    http.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout');
        }
        throw err;
      });
    });
  }
};
</script>
<style lang="scss">
@import 'assets/styles/tailwind.scss';
</style>
