import http from '@/http';
import utils from '@/utils';

const stateObj = {
  ads: null,
  adsData: {}
};

const mutations = {
  set_ads(state, payload) {
    state.ads = payload;
  },
  set_ad_data(state, { advert, data }) {
    state.adsData = {
      ...state.adsData,
      [advert]: data
    };
  }
};

const actions = {
  getAds({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      http
        .get(utils.sanitizeApiUrl('/api/packers/stats_tracking_ids'))
        .then(response => {
          const ads = response.data.content;
          commit('set_ads', ads);
          // Fetch data for each ad
          ads.forEach(ad => {
            dispatch('getAdData', ad);
          });
          resolve(response);
        })
        .catch(err => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  },
  getAdData({ commit }, advert) {
    return new Promise((resolve, reject) => {
      http
        .get(utils.sanitizeApiUrl('/api/packers/stats_tracking'), {
          params: { advert }
        })
        .then(response => {
          commit('set_ad_data', { advert, data: response.data.content });
          resolve(response);
        })
        .catch(err => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  }
};

const getters = {
  getAdsData: state => state.ads,
  getAdStats: state => advert => state.adsData[advert]
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
