<template>
  <div>
    <toast
      :show="showInactivityWarning"
      :countdown="warningCountdown"
      title="Session Timeout Warning"
      message="Your session will expire due to inactivity."
      @stay-active="resetInactivityTimer"
      @logout="handleLogout"
    />
  </div>
</template>

<script>
import Toast from '@/components/common/ui/Toast.vue';

const INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes
const WARNING_DURATION = 2 * 60 * 1000; // 2 minutes warning

export default {
  name: 'InactivityTracker',
  components: {
    Toast
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    },
    isPackingMachine: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      inactivityTimer: null,
      warningTimer: null,
      showInactivityWarning: false,
      warningCountdown: 0,
      countdownInterval: null,
      timeUntilWarning: Math.floor(
        (INACTIVITY_TIMEOUT - WARNING_DURATION) / 1000
      ),
      timeTrackingInterval: null,
      activityHandler: null
    };
  },
  methods: {
    resetInactivityTimer() {
      this.showInactivityWarning = false;
      this.warningCountdown = 0;
      this.timeUntilWarning = Math.floor(
        (INACTIVITY_TIMEOUT - WARNING_DURATION) / 1000
      );
      clearInterval(this.countdownInterval);
      clearInterval(this.timeTrackingInterval);
      clearTimeout(this.inactivityTimer);
      clearTimeout(this.warningTimer);

      if (this.isLoggedIn && this.isPackingMachine) {
        this.inactivityTimer = setTimeout(() => {
          this.showWarning();
        }, INACTIVITY_TIMEOUT - WARNING_DURATION);
      }
    },
    showWarning() {
      this.showInactivityWarning = true;
      this.warningCountdown = WARNING_DURATION / 1000;

      this.countdownInterval = setInterval(() => {
        this.warningCountdown--;
      }, 1000);

      this.warningTimer = setTimeout(() => {
        this.handleLogout();
      }, WARNING_DURATION);
    },
    handleLogout() {
      clearInterval(this.countdownInterval);
      clearInterval(this.timeTrackingInterval);
      clearTimeout(this.inactivityTimer);
      clearTimeout(this.warningTimer);
      this.showInactivityWarning = false;
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
    setupInactivityTracking() {
      if (!this.isPackingMachine) return;

      // Create a debounced event handler
      this.activityHandler = this.debounce(() => {
        if (!this.showInactivityWarning) {
          this.resetInactivityTimer();
        }
      }, 150);

      const events = [
        'mousemove',
        'mousedown',
        'keypress',
        'touchstart',
        'scroll'
      ];

      events.forEach(event => {
        document.addEventListener(event, this.activityHandler, {
          passive: true
        });
      });

      // Start the initial timer
      this.resetInactivityTimer();
    },
    debounce(func, wait) {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    },
    cleanupEventListeners() {
      if (this.activityHandler) {
        const events = [
          'mousemove',
          'mousedown',
          'keypress',
          'touchstart',
          'scroll'
        ];
        events.forEach(event => {
          document.removeEventListener(event, this.activityHandler);
        });
      }
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.setupInactivityTracking();
    }
  },
  beforeDestroy() {
    this.cleanupEventListeners();
    clearInterval(this.countdownInterval);
    clearInterval(this.timeTrackingInterval);
    clearTimeout(this.inactivityTimer);
    clearTimeout(this.warningTimer);
  },
  watch: {
    isLoggedIn(newVal) {
      if (newVal) {
        this.setupInactivityTracking();
      } else {
        this.cleanupEventListeners();
      }
    }
  }
};
</script>
